.seat-map-container {
  min-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.grid-container {
  display: grid;
  grid-template-columns: 50px 50px 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.911);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
.seat-item {
  cursor: pointer;
}
.area-name {
  color: #333;
  display: inline-block;
  margin: 10px 10px 20px 10px;
  padding: 24px 12px 12px;
  background: rgba(235, 231, 231, 0.3);
  border-radius: 25px 25px 5px 5px;
}
svg.selected .icon-selected {
  fill: rgb(39, 174, 96);
}
svg.reserved .icon-disabled {
  fill: rgb(242, 242, 242);
}
svg.selected rect {
  stroke: rgb(39, 174, 96);
  fill: rgb(139, 229, 176);
}
.driver-seat {
  margin: 0 0 5px 15px;
  cursor: not-allowed;
}
.svg.reserved {
  cursor: not-allowed;
}
svg.reserved rect {
  stroke: rgb(242, 242, 242);
  fill: rgb(224, 224, 224);
}
